body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('./background.png') no-repeat center center/cover;
}

.App-logo {
  height: 80px;
  /* Adjust size as needed */
  margin-bottom: 20px;
}


.App-header {
  background-color: white;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
  transition: all 0.3s ease;
}

.App-header:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.slogan {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 30px;
  font-style: italic;
}

.summary {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 40px;
}

.learn-more-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: darkorange;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.learn-more-button:hover {
  background-color: #3a91d4;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}